import React from "react";
import { enrollIntroduction } from "../../assets/data/enroll";
import Assistance from "../Assistance";

const EnrollIntroduction = ({ step, setStep }) => {
  return (
    <>
      <Assistance
        body={
          "Please share details about your debts and actions being taken by creditors."
        }
      />
      <div className="assistance-seperator"></div>
      <div className="enroll-introduction">
        {enrollIntroduction.map((item, index) => {
          return (
            <div className="enroll-card" key={index}>
              <div className="enroll-number">
                <h1>{index + 1}</h1>
              </div>
              <div className="enroll-header">
                <img src={item.img} alt="" />
                <h1>{item.title}</h1>
              </div>
            </div>
          );
        })}
        <div className="enroll-pink-box my-4">
          <h2 className="fw-bold">
            Our solutions are tailored to help you become debt-free while
            improving your credit score, enabling you to qualify for new loans
            after successfully completing the debt management program (DMP).
          </h2>
        </div>
        <div className="text-center">
          <button
            className="button"
            onClick={() => setStep(step + 1)}
            id="introduction-button"
          >
            Proceed
          </button>
        </div>
      </div>
    </>
  );
};

export default EnrollIntroduction;
