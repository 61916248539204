import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import axios from "axios";
import { FaRupeeSign, FaTimes } from "react-icons/fa";
import Assistance from "../Assistance";

const EnrollForm2 = ({
  step,
  setStep,
  inputs,
  handleInputs,
  errors,
  setErrors,
}) => {
  const { url, getAccountStatus, getToken } = useContext(AppContext);
  //
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  //
  const validate = () => {
    const { income, expense } = inputs;

    const newErrors = {};

    if (!income) {
      newErrors.income = "Enter your total income";
    } else if (isNaN(income)) {
      newErrors.income = "Enter a valid number";
    } else if (!Number.isInteger(Number(income))) {
      newErrors.income = "Decimals are not allowed for income";
    }
    if (!expense) {
      newErrors.expense = "Enter your total expense";
    } else if (isNaN(expense)) {
      newErrors.expense = "Enter a valid number";
    } else if (!Number.isInteger(Number(expense))) {
      newErrors.expense = "Decimals are not allowed for expense";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setModalVisible(true);
    }
  };
  //
  const submit = async () => {
    const { income, expense } = inputs;
    try {
      setLoading(true);
      const sdUser = JSON.parse(localStorage.getItem("sdUser"));
      const result = await getAccountStatus(sdUser);
      if (result.id === null) {
        setMessage("Please wait, your account is being created.");
      } else {
        const data = [
          {
            Income: income,
            Expenses: expense,
            Step: 2,
          },
        ];
        const token = await getToken();
        const res = await axios.put(
          `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/${result.id}`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Zoho-oauthtoken ${token}`,
            },
          }
        );
        if (res.status === 200) {
          setStep(step + 1);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <>
      <Assistance
        body={
          "Please share your income & expenditure so we can assist you effectively."
        }
      />
      <div className="assistance-seperator"></div>
      <div className="enroll-div enroll-form-2">
        <h1 className="primary-text text-center">Your income and expenses</h1>
        <div className="mt-4">
          <div className="mb-3">
            <div className="form-control">
              <input
                type="tel"
                name="income"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.income}
                onChange={handleInputs}
              />
              <label htmlFor="income" className="form-label input-label">
                Enter your total income
              </label>
            </div>
            <p className="text-danger">{errors.income}</p>
          </div>
          <div className="mb-3">
            <div className="form-control">
              <input
                type="tel"
                name="expense"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.expense}
                onChange={handleInputs}
              />
              <label htmlFor="expense" className="form-label input-label">
                Enter your total expense
              </label>
            </div>
            <p className="text-danger">{errors.expense}</p>
          </div>
        </div>
        <div className="text-center">
          <p className="text-danger mb-2">{message}</p>
          <div className="enroll-button-flex">
            <button
              className="button"
              onClick={() => setStep(step - 1)}
              id="back-to-form-1"
            >
              Back
            </button>
            <button className="button text-center" onClick={validate}>
              proceed
            </button>
          </div>
        </div>
      </div>

      {/*  */}
      <div className={`custom-modal-container ${modalVisible ? "active" : ""}`}>
        <div
          className="custom-modal-background"
          onClick={() => setModalVisible(false)}
        ></div>
        <div className="custom-modal-box">
          <div className="header p-2">
            <p className="fw-bold text-capitalize">Your income and expense</p>
          </div>
          <div className="body p-2">
            <p>
              Your income:{" "}
              <span className="fw-bold">
                <FaRupeeSign />
                {inputs.income}
              </span>
            </p>
            <p>
              your expense:{" "}
              <span className="fw-bold">
                <FaRupeeSign />
                {inputs.expense}
              </span>
            </p>
          </div>
          <div className="footer text-end p-2">
            <div className="modal-button-flex">
              <button
                className="button text-center"
                style={{ minWidth: "80px" }}
                onClick={() => setModalVisible(false)}
              >
                Close
              </button>
              <button
                className="button text-center"
                style={{ minWidth: "80px" }}
                onClick={submit}
                id="form-2-button"
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>Confirm</>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnrollForm2;
