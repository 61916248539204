import React, { useContext, useEffect, useState } from "react";
import Assistance from "../components/Assistance";
import laptopBoi from "../assets/images/laptop-boi.png";
import { AppContext } from "../context/AppContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ThankYou = () => {
  const { url, getToken } = useContext(AppContext);
  const navigate = useNavigate("");
  const [loading, setLoading] = useState(false);
  const verifyPayment = async () => {
    try {
      setLoading(true);
      let token = await getToken();
      const sdUser = localStorage.getItem("sdUser");
      if (sdUser) {
        const res = await axios.get(
          `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/search?criteria=((Phone_Number:equals:${JSON.parse(
            sdUser
          )}))`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Zoho-oauthtoken ${token}`,
            },
          }
        );
        if (
          res.data.data[0].Payment_Status !== "Paid" &&
          res.data.data[0].step !== 4
        ) {
          navigate("/enroll", { replace: true });
        }
      } else {
        navigate("/login", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    verifyPayment();
  }, []);
  return (
    <>
      <Assistance body={"Thank you for enrolling with SingleDebt"} />
      <div className="assistance-seperator"></div>
      <div className="payment-success pb-5 px-2">
        <div className="laptop-boi">
          <img src={laptopBoi} alt="" />
        </div>

        <div className="text-center mt-4">
          <h1 className="mb-4">🎉 Exciting update! Enrollment Confirmed.</h1>
          <p>
            Thank you for enrolling! To activate your account and initiate
            payments to your creditors, please complete your first EMI payment
            at your earliest convenience.
          </p>

          {/* <div className="my-4">
            <button className="button">Make EMI payment</button>
          </div> */}

          <p>
            This link is valid for 12 hours, so please complete your setup
            before it expires. <br />
            <br />
            If you have any questions, we're here to help! Call us at{" "}
            <a href="tel:022 68762605" className="primary-text fw-bold">
              022 68762605
            </a>{" "}
            between 9 AM and 7 PM, Monday to Saturday, or access live support.
          </p>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
