import React from "react";
import colorLogo from "../assets/images/color logo.png";
import { Link } from "react-router-dom";
import { FaPowerOff } from "react-icons/fa";

const Navbar = ({ setModalVisible }) => {
  return (
    <>
      <div className="container-fluid bg-white p-2 sticky-top d-flex align-items-center justify-content-between">
        <div className="logo">
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img src={colorLogo} alt="logo" loading="lazy" />
          </Link>
        </div>
        <button className="button" onClick={() => setModalVisible(true)}>
          <FaPowerOff />
        </button>
      </div>
    </>
  );
};

export default Navbar;
