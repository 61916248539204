import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import whitelogo from "../assets/images/white logo.png";
import { FaArrowRight } from "react-icons/fa";
import axios from "axios";
import googleStore from "../assets/images/Google-Store.webp";
import appStore from "../assets/images/App-Store.webp";
import { AppContext } from "../context/AppContext";

const Signup = () => {
  const { url, getToken } = useContext(AppContext);
  const navigate = useNavigate();
  //
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  //
  const [searchParams] = useSearchParams();
  const signup = async () => {
    const utmSource = searchParams.get("utm_source");

    const { name, email, mobile } = inputs;
    if (!name) {
      setError(true);
      setMessage("Please enter your full namer");
    } else if (!email) {
      setError(true);
      setMessage("Please enter your email address");
    } else if (!mobile) {
      setError(true);
      setMessage("Please enter your mobile number");
    } else if (name.split(" ").length < 2) {
      setError(true);
      setMessage("Please enter your full name");
    } else {
      try {
        setLoading(true);
        const token = await getToken();
        //
        const firstName = name.split(" ")[0];
        const lastName = name.replace(name.split(" ")[0], "");
        const data = [
          {
            First_Name: firstName,
            Last_Name: lastName,
            Email: email,
            Phone_Number: mobile,
            Account_Status: "Enrolled",
            Sources:
              !utmSource || utmSource === ""
                ? "Enroll_Portal_Client"
                : utmSource,
            Service_Offered: "Online Personal Debt Program",
          },
        ];
        const createUser = await axios.post(
          `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Zoho-oauthtoken ${token}`,
            },
          }
        );
        if (createUser.status === 201) {
          setError(false);
          setMessage("Account created. Please login to continue.");
          localStorage.setItem("sdUser", JSON.stringify(inputs.mobile));
          navigate("/enroll", { replace: true });
        } else if (createUser.status === 202) {
          const field = createUser.data.data[0].details.api_name;
          //
          if (field === "Email") {
            setError(true);
            setMessage(`Duplicate data: Email address.`);
          } else if (field === "Phone_Number") {
            setError(true);
            setMessage(`Account already exists. Please login.`);
            setTimeout(() => {
              navigate("/login", { replace: true });
              setError(false);
            }, 3000);
          }
        } else if (!createUser.data || createUser.status === 204) {
          setError(true);
          setMessage(`Something went wrong.`);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };
  //
  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  }, [message]);
  return (
    <>
      <div className="login-page">
        <div className="left-part">
          <div className="inner-part">
            <Link to="/" className="logo">
              <img src={whitelogo} alt="logo" loading="lazy" />
            </Link>

            <div>
              <h2>Welcome to SingleDebt Portal</h2>
              <p>
                Where your financial journey begins towards a debt-free future
              </p>
            </div>

            <ul className="d-flex align-items-lg-center align-items-start justify-content-start flex-lg-row flex-column terms-list">
              <li>
                <Link to="/termsconditions">Term & Conditions</Link>
              </li>
              <li>
                <a
                  href="https://singledebt.in/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy policy
                </a>
              </li>
              <li>
                <Link to="/faq">FAQ</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="right-part">
          <div className="inner-part">
            <div>
              <h2>Signup</h2>
              <p>Enter details your details below</p>
            </div>
            <div className="login-form my-5">
              <div className="mb-3">
                <div className="form-control">
                  <input
                    type="text"
                    name="name"
                    className="form-input"
                    placeholder="full name"
                    required
                    autoComplete="off"
                    value={inputs.name}
                    onChange={handleInputs}
                  />
                  <label htmlFor="name" className="form-label input-label">
                    full name
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-control">
                  <input
                    type="email"
                    name="email"
                    className="form-input"
                    placeholder="email"
                    required
                    autoComplete="off"
                    value={inputs.email}
                    onChange={handleInputs}
                  />
                  <label htmlFor="email" className="form-label input-label">
                    Email address
                  </label>
                </div>
              </div>
              <div className="mb-3">
                <div className="form-control">
                  <input
                    type="tel"
                    name="mobile"
                    className="form-input"
                    placeholder="mobile"
                    required
                    autoComplete="off"
                    value={inputs.mobile}
                    onChange={handleInputs}
                  />
                  <label htmlFor="mobile" className="form-label input-label">
                    mobile number
                  </label>
                </div>
              </div>
              {message && (
                <p
                  className={`${
                    error ? "text-danger" : "text-success"
                  } fw-bold`}
                >
                  {message}
                </p>
              )}
              <button
                className="button"
                style={{ justifyContent: loading ? "center" : "space-between" }}
                onClick={signup}
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>
                    Signup
                    <FaArrowRight />
                  </>
                )}
              </button>
              <h2 className="mt-4">
                Already have an account?{" "}
                <Link to="/login" className="login-link">
                  Login
                </Link>
              </h2>
            </div>

            <div className="d-flex align-items-sm-center align-items-start justify-content-start gap-2 flex-sm-row flex-column">
              <a
                href="https://play.google.com/store/apps/details?id=com.singledebt&hl=en_IN"
                target="_blank"
                rel="noreferrer"
                className="store-image"
              >
                <img src={googleStore} className="invert-image" alt="" />
              </a>
              <a
                href="https://apps.apple.com/in/app/singledebt/id6480590793"
                target="_blank"
                rel="noreferrer"
                className="store-image"
              >
                <img src={appStore} className="invert-image" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
