import axios from "axios";
import React, { createContext, useState } from "react";
const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const url = "https://api.singledebt.in";
  const [user, setUser] = useState(null);
  //
  const getToken = async () => {
    const res1 = await axios.get(`${url}/token`);
    if (res1.status === 400) {
      console.log("Something went wrong");
    } else if (res1.status === 200) {
      if (res1.data.token.length === 0) {
        return null;
      } else {
        return res1.data.token[0].token;
      }
    }
  };
  //
  const getAccountStatus = async (number) => {
    try {
      let token = await getToken();
      const res = await axios.get(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/search?criteria=((Phone_Number:equals:${number}))`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      if (!res.data || res.status === 204) {
        return {
          status: "Account not found",
          id: "",
          step: "",
          totalUnsecuredDebts: "",
          numberOfUnsecuredDebts: "",
          totalEMI: "",
          missedEMI: "",
          harassmentType: "",
          legalActionType: "",
          income: "",
          expense: "",
          months: "",
          emi: "",
          total: "",
          paymentStatus: "",
        };
      } else {
        return {
          status: res.data.data[0].Account_Status,
          id: res.data.data[0].id,
          step: res.data.data[0].Step === null ? "" : res.data.data[0].Step,
          totalUnsecuredDebts:
            res.data.data[0].Outstanding === null
              ? ""
              : res.data.data[0].Outstanding,
          numberOfUnsecuredDebts:
            res.data.data[0].No_Of_Loans === null
              ? ""
              : res.data.data[0].No_Of_Loans,
          totalEMI:
            res.data.data[0].EMI_Payments === null
              ? ""
              : res.data.data[0].EMI_Payments,
          missedEMI:
            res.data.data[0].Missed_Payment === null
              ? ""
              : res.data.data[0].Missed_Payment,
          harassmentType:
            res.data.data[0].Harassment_Type === null
              ? ""
              : res.data.data[0].Harassment_Type,
          legalActionType:
            res.data.data[0].Legal_Status === null
              ? ""
              : res.data.data[0].Legal_Status,
          income:
            res.data.data[0].Income === null ? "" : res.data.data[0].Income,
          expense:
            res.data.data[0].Expenses === null ? "" : res.data.data[0].Expenses,
          months:
            res.data.data[0].Plan_Type === null
              ? ""
              : res.data.data[0].Plan_Type,
          emi:
            res.data.data[0].Monthly_EMI_Payment === null
              ? ""
              : res.data.data[0].Monthly_EMI_Payment,
          total:
            res.data.data[0].Total_Plan_Amount === null
              ? ""
              : res.data.data[0].Total_Plan_Amount,
          paymentStatus:
            res.data.data[0].Enroll_Payment_Status === null
              ? ""
              : res.data.data[0].Enroll_Payment_Status,
        };
      }
    } catch (error) {
      console.log(error);
      return { status: "Error", id: null };
    }
  };
  //
  const preventChange = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };
  const preventScroll = (e) => {
    if (document.activeElement === e.target) {
      e.preventDefault(); // Prevents the scroll only if the input is focused
    }
  };
  return (
    <AppContext.Provider
      value={{
        url,
        user,
        setUser,
        getToken,
        getAccountStatus,
        preventChange,
        preventScroll,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
