import React, { useContext, useEffect, useState } from "react";
import { FaCheckCircle, FaRupeeSign } from "react-icons/fa";
import { AppContext } from "../../context/AppContext";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Assistance from "../Assistance";
import { useNavigate } from "react-router-dom";

const Payment = ({ step, setStep }) => {
  const { url, getAccountStatus, getToken } = useContext(AppContext);
  const navigate = useNavigate();
  const [result, setResult] = useState(null);
  useEffect(() => {
    const getData = async () => {
      const sdUser = JSON.parse(localStorage.getItem("sdUser"));
      setResult(await getAccountStatus(sdUser));
    };
    getData();
  }, []);
  //
  const successToast = (message) => toast.success(message);
  const errorToast = (message) => toast.error(message);
  const [buttonDisable, setButtonDisable] = useState(false);
  //
  const createOrder = async () => {
    if (result !== null) {
      const data1 = {
        amount: 599,
      };
      try {
        const res1 = await axios.post(`${url}/create-order`, data1, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        //
        if (res1.status === 200) {
          const data2 = {
            key: "rzp_live_rTeC0Xl72J36OB",
            amount: 599 * 100,
            order_id: res1.id,
            theme: {
              color: "#ff4865",
            },
            handler: () => {
              verifyPayment(res1.status, res1.data);
            },
            modal: {
              ondismiss: () => {
                errorToast("Payment cancelled");
              },
            },
          };
          localStorage.setItem(
            "razorpay_payment_info",
            JSON.stringify({
              id: res1.data.id,
              created_at: res1.data.created_at,
            })
          );
          const razorpay = new window.Razorpay(data2);
          razorpay.open();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  //
  const verifyPayment = async (status1, res1) => {
    const localItems = localStorage.getItem("razorpay_payment_info");
    const razorpay_payment_info = JSON.parse(localItems);
    if (status1 === 200 && res1.id === razorpay_payment_info.id) {
      const date = new Date();

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      const data = [
        {
          Enroll_Payment_Date: formattedDate, // format('YYYY-MM-DD')
          Enroll_Payment_Status: "Paid",
          Enroll_Payment_ID: razorpay_payment_info.id,
          Step: "4",
        },
      ];
      const token = await getToken();
      const res = await axios.put(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/${result.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setButtonDisable(true);
        successToast("Payment done successfully!");
        setTimeout(() => {
          navigate("/thankyou", { replace: true });
        }, 1000);
      } else {
        errorToast("Something went wrong.");
      }
    } else if (status1 === 400) {
      errorToast("Payment was cancelled.");
    }
  };
  return (
    <>
      <Assistance body={"Your Harassment and Debt Solutions"} />
      <div className="assistance-seperator"></div>
      <div className="py-4">
        {result !== null ? (
          <>
            <div className="payment-list-items">
              <h1 className="text-center">
                Get started today for just <FaRupeeSign />
                599
              </h1>

              <ul className="payment-list-items">
                <li className="list-item-flex mb-3">
                  <FaCheckCircle />
                  Achieve debt freedom within {result.months}.
                </li>
                {result.totalEMI - result.total >= 0 ? (
                  <li className="list-item-flex mb-3">
                    <FaCheckCircle />
                    <span>
                      Enjoy affordable EMI's - pay only{" "}
                      <FaRupeeSign className="text-black" />
                      {result.total.toLocaleString("en-IN")} per month, saving{" "}
                      <FaRupeeSign className="text-black" />
                      {result.totalEMI - result.total} compared to your original
                      EMI payments.
                    </span>
                  </li>
                ) : (
                  ""
                )}

                <li className="list-item-flex mb-3">
                  <FaCheckCircle />
                  Say goodbye to creditor harassment and regain peace of mind.
                </li>
                <li className="list-item-flex mb-3">
                  <FaCheckCircle />
                  Start improving your credit score and be ready to access
                  future loans.
                </li>
              </ul>
            </div>

            <div className="payment-details-container">
              <div className="payment-details-box">
                <h2>Selected Plan</h2>
                <h2>{result.months}</h2>
              </div>
              <div className="payment-details-box my-4">
                <h2>Total monthly EMI</h2>
                <h2>
                  <FaRupeeSign />
                  {result.total.toLocaleString("en-IN")}
                </h2>
              </div>
              <div className="payment-details-box">
                <h2>Enrollment fees</h2>
                <h2>
                  <FaRupeeSign />
                  599
                </h2>
              </div>
              <div className="text-center mt-4">
                <div className="enroll-button-flex">
                  <button
                    className="button payment-details-button"
                    onClick={() => setStep(step - 1)}
                    id="back-to-plan"
                  >
                    Back
                  </button>
                  <button
                    className={`button payment-details-button ${
                      buttonDisable ? "disabled-button" : ""
                    }`}
                    onClick={createOrder}
                    disabled={buttonDisable === true ? true : false}
                    id="enroll-now-button"
                  >
                    Enroll now for just <FaRupeeSign />
                    599
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <ToastContainer position="top-right" autoClose={2000} theme="light"  />
    </>
  );
};

export default Payment;
