import React, { useContext, useEffect, useState } from "react";
import { FaRupeeSign } from "react-icons/fa";
import { AppContext } from "../../context/AppContext";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Assistance from "../Assistance";
import { description } from "../../assets/data/enroll";
import temp from "../../assets/images/temp.png";

const Plans = ({ step, setStep }) => {
  const { url, getAccountStatus, getToken } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [planLoading, setPlanLoading] = useState(false);
  const [recommended, setRecommended] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [plans, setPlans] = useState([
    {
      months: 12,
      emi: 0,
      subscription: 0,
      total: 0,
    },
    {
      months: 18,
      emi: 0,
      subscription: 0,
      total: 0,
    },
    {
      months: 24,
      emi: 0,
      subscription: 0,
      total: 0,
    },
    {
      months: 36,
      emi: 0,
      subscription: 0,
      total: 0,
    },
    {
      months: 48,
      emi: 0,
      subscription: 0,
      total: 0,
    },
    {
      months: 60,
      emi: 0,
      subscription: 0,
      total: 0,
    },
  ]);
  useEffect(() => {
    const restructure = async () => {
      setLoading(true);
      const sdUser = JSON.parse(localStorage.getItem("sdUser"));
      const result = await getAccountStatus(sdUser);
      const calculateDI =
        parseFloat(result.income) - parseFloat(result.expense);
      //
      const updatedPlans = plans.map((item, index) => {
        const emi =
          parseFloat(result.totalUnsecuredDebts) / parseFloat(item.months);

        const subscription = (parseFloat(emi) * 20) / 100;
        const total = parseFloat(emi) + parseFloat(subscription);

        return {
          ...item,
          index,
          emi: parseFloat(emi.toFixed(0)),
          subscription: parseFloat(subscription.toFixed(0)),
          total: parseFloat(total.toFixed(0)),
        };
      });
      setPlans(updatedPlans);
      //
      const eligiblePlans = updatedPlans.filter(
        (item) => item.total < calculateDI && item.total > 9999
      );
      if (eligiblePlans.length > 0) {
        const closestPlan = eligiblePlans.reduce((prev, curr) =>
          Math.abs(curr.total - calculateDI) <
          Math.abs(prev.total - calculateDI)
            ? curr
            : prev
        );
        setRecommended(closestPlan.index);
      } else {
        setRecommended(null);
      }
      //
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    };
    restructure();
  }, []);

  //
  const [tempLoad, setTempLoad] = useState(null);
  const selectPlan = async (selectedPlan, index) => {
    try {
      setPlanLoading(true);
      setTempLoad(index);
      const sdUser = JSON.parse(localStorage.getItem("sdUser"));
      const result = await getAccountStatus(sdUser);
      const token = await getToken();

      const data = [
        {
          Plan_Type: `${selectedPlan.months} months`,
          Monthly_EMI_Payment: selectedPlan.emi.toString(),
          Total_Plan_Amount: selectedPlan.total.toString(),
          Subscription_Fees: selectedPlan.subscription.toString(),
          Step: "3",
        },
      ];
      const res = await axios.put(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/${result.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      if (res.status === 200 && res.data.data[0].code === "SUCCESS") {
        setStep(step + 1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPlanLoading(false);
    }
  };
  return (
    <>
      <Assistance
        body={
          "After reviewing all the information, your tailored solutions are below"
        }
      />
      <div className="assistance-seperator"></div>
      <div className="description pb-4">
        <h1 className="text-center primary-text">
          Harassment and debt solutions
        </h1>
        <div className="description-grid mt-4">
          {description.slice(0, 2).map((item, index) => {
            return (
              <div
                className={`description-card description-card-${index + 1}`}
                key={index}
              >
                <div className="description-img">
                  <img src={item.img} alt="" />
                </div>
                <div>
                  <h2 className="primary-text">{item.title}</h2>
                  <p>{item.body}</p>
                  {index === 2 ? (
                    <button
                      className="button description-button"
                      onClick={() => setModalVisible(true)}
                    >
                      Read more
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <h1 className="primary-text text-center mb-4">
          Select your affordable plan below
        </h1>
        {loading ? (
          <div className="text-center my-4">
            <div
              className="spinner-border spinner-border-xxl pink-loader"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <Swiper
              spaceBetween={8}
              slidesPerView={1}
              pagination={{ clickable: true }}
              navigation
              modules={[Navigation]}
              initialSlide={0}
              loop
              breakpoints={{
                200: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                },
              }}
            >
              {plans.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      className={`plan-card ${
                        recommended === index
                          ? "justify-content-between"
                          : "justify-content-evenly"
                      }`}
                    >
                      {recommended === index ? (
                        <div className="recommended">
                          <p>Recommended</p>
                        </div>
                      ) : (
                        ""
                      )}
                      <div>
                        <h2>plan No {index + 1}</h2>
                        <h2>{item.months} months plan</h2>
                      </div>

                      {item.emi < 9999 ? (
                        <p>
                          You are not eligible for this plan because your EMI is
                          coming out to be less than 10,000.
                        </p>
                      ) : (
                        <>
                          <div>
                            <div className="plan-middle">
                              <div className="plan-flex">
                                <p>Monthly EMI</p>
                                <p className="fw-bold">
                                  <FaRupeeSign />
                                  {item.emi.toLocaleString("en-IN")}
                                </p>
                              </div>
                              <div className="plan-flex">
                                <p>Subscription</p>
                                <p className="fw-bold">
                                  <FaRupeeSign />
                                  {item.subscription.toLocaleString("en-IN")}
                                </p>
                              </div>
                            </div>
                            <div className="plan-flex mt-2">
                              <p className="fw-bold">Total monthly EMI</p>
                              <p className="fw-bold">
                                <FaRupeeSign />
                                {item.total.toLocaleString("en-IN")}
                              </p>
                            </div>
                          </div>
                          <div className="plan-flex">
                            <p>One Time Fee</p>
                            <p className="fw-bold">
                              <FaRupeeSign />
                              599
                            </p>
                          </div>
                        </>
                      )}

                      <button
                        className={`button plan-button ${
                          item.emi < 9999 ? "disabled-button" : ""
                        }`}
                        disabled={item.emi < 9999 ? true : false}
                        onClick={() => selectPlan(item, index)}
                        style={{ minWidth: "65px" }}
                        id={`plan${index + 1}`}
                      >
                        {tempLoad === index && planLoading ? (
                          <div className="text-center">
                            <div
                              className="spinner-border spinner-border-sm pink-loader"
                              role="status"
                            >
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </div>
                          </div>
                        ) : (
                          "Select"
                        )}
                      </button>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </>
        )}
      </div>

      <div className="golden-box">
        <h1 className="text-center">
          Our one time fee <FaRupeeSign />
          599{" "}
        </h1>
        <div>
          {description[2].body.map((item, index) => {
            return (
              <div
                className={`${
                  index === description[2].body.length - 1 ? "" : "mb-2"
                } goldren-flex`}
                key={index}
              >
                <img src={item.img} alt="" />
                <p className="fw-bold">{item.content}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="text-center mt-4">
        <button
          className="button"
          onClick={() => setStep(step - 1)}
          id="back-to-form2"
        >
          Back
        </button>
      </div>

      {/*  */}
      {/* <div
        className={`custom-modal-container text-center ${
          modalVisible ? "active" : ""
        }`}
      >
        <div
          className="custom-modal-background"
          onClick={() => setModalVisible(false)}
        ></div>
        <div className="custom-modal-box">
          <div className="header p-2 justify-content-center">
            <p className="fw-bold text-capitalize">
              {description[2].subTitles.title1} &{" "}
              {description[2].subTitles.title2}
            </p>
          </div>
          <div className="body p-2">
            <div className="mb-4">
              <h2 className="primary-text">
                {description[2].subTitles.title1}
              </h2>
              <p>{description[2].subTitles.text1}</p>
            </div>
            <div>
              <h2 className="primary-text">
                {description[2].subTitles.title2}
              </h2>
              <p>{description[2].subTitles.text2}</p>
            </div>
          </div>
          <div className="footer p-2 text-end">
            <button className="button" onClick={() => setModalVisible(false)}>
              Close
            </button>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Plans;
