import React, { useContext, useMemo, useState } from "react";
import axios from "axios";
import { AppContext } from "../../context/AppContext";
import { FaPhoneAlt, FaRupeeSign } from "react-icons/fa";
import Assistance from "../Assistance";

const EnrollForm1 = ({
  step,
  setStep,
  inputs,
  handleInputs,
  errors,
  setErrors,
}) => {
  const { url, getAccountStatus, getToken, preventChange } =
    useContext(AppContext);
  //
  const [error, setError] = useState(true);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  //
  const validate = () => {
    const {
      numberOfUnsecuredDebts,
      totalUnsecuredDebts,
      totalEMI,
      missedEMI,
      harassmentType,
      legalActionType,
    } = inputs;

    const newErrors = {};

    if (!totalUnsecuredDebts) {
      newErrors.totalUnsecuredDebts = "Enter your total unsecured debts";
    } else if (parseFloat(totalUnsecuredDebts) < 120000) {
      setLessOSModal(true);
    } else if (isNaN(totalUnsecuredDebts)) {
      newErrors.totalUnsecuredDebts =
        "Please enter a valid number for outstanding";
    } else if (!Number.isInteger(Number(totalUnsecuredDebts))) {
      newErrors.totalUnsecuredDebts =
        "Decimals are not allowed for total unsecured debts";
    }
    if (!numberOfUnsecuredDebts) {
      newErrors.numberOfUnsecuredDebts = "Enter your number of unsecured debts";
    } else if (isNaN(numberOfUnsecuredDebts)) {
      newErrors.numberOfUnsecuredDebts =
        "Please enter a valid number for debts";
    } else if (!Number.isInteger(Number(numberOfUnsecuredDebts))) {
      newErrors.numberOfUnsecuredDebts =
        "Decimals are not allowed for debt number";
    }
    if (!totalEMI) {
      newErrors.totalEMI = "Enter your total unsecured EMI";
    } else if (isNaN(totalEMI)) {
      newErrors.totalEMI = "Please enter a valid number for EMI";
    } else if (!Number.isInteger(Number(totalEMI))) {
      newErrors.totalEMI = "Decimals are not allowed for EMI amount";
    }
    if (!harassmentType) {
      newErrors.harassmentType = "Select the type of harassment";
    }
    if (!missedEMI) {
      newErrors.missedEMI = "This field is mandatory";
    }
    if (!legalActionType) {
      newErrors.legalActionType = "Select the type of legal action";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setModalVisible(true);
    }
  };
  //
  const submit = async () => {
    try {
      const sdUser = JSON.parse(localStorage.getItem("sdUser"));
      setLoading(true);
      setError(false);
      setMessage("Please hold on. This might take a minute or two.");
      const retryGetAccountStatus = async (
        attempt = 1,
        maxAttempts = 20,
        delay = 5000
      ) => {
        const result = await getAccountStatus(sdUser);

        if (result.id) {
          handleSubmit(result);
          setLoading(false);
          setError(false);
        } else if (attempt < maxAttempts) {
          setTimeout(
            () => retryGetAccountStatus(attempt + 1, maxAttempts, delay),
            delay
          );
        } else {
          setMessage(
            "Failed to retrieve account status after several attempts. Please try again later."
          );
        }
      };

      await retryGetAccountStatus();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (result) => {
    const {
      numberOfUnsecuredDebts,
      totalUnsecuredDebts,
      totalEMI,
      missedEMI,
      harassmentType,
      legalActionType,
    } = inputs;
    try {
      const data = [
        {
          No_Of_Loans: numberOfUnsecuredDebts,
          Outstanding: totalUnsecuredDebts,
          EMI_Payments: totalEMI,
          Missed_Payment: missedEMI,
          Harassment_Type: harassmentType,
          Legal_Status: legalActionType,
          Step: 1,
        },
      ];
      const token = await getToken();
      const res = await axios.put(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/${result.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setStep(step + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  useMemo(() => {
    if (message !== "" && error === true) {
      setTimeout(() => {
        setMessage("");
      }, 10000);
    }
  }, [message]);
  //
  const [modalVisible, setModalVisible] = useState(false);
  const [lessOSModal, setLessOSModal] = useState(false);
  return (
    <>
      <Assistance
        body={
          "Please share details about your debts and actions being taken by creditors."
        }
      />
      <div className="assistance-seperator"></div>
      <div className="enroll-div enroll-form-1">
        <h1 className="primary-text text-center">
          Your unsecure debts and type of harassment
        </h1>
        <div className="mt-4">
          <div className="mb-3">
            <div className="form-control">
              <input
                type="number"
                name="totalUnsecuredDebts"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.totalUnsecuredDebts}
                onChange={handleInputs}
                onKeyDown={preventChange}
                min="1"
              />
              <label
                htmlFor="totalUnsecuredDebts"
                className="form-label input-label"
              >
                Total unsecured debts (Rs)
              </label>
            </div>
            <p className="text-danger">{errors.totalUnsecuredDebts}</p>
          </div>
          <div className="mb-3">
            <div className="form-control">
              <input
                type="number"
                name="numberOfUnsecuredDebts"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.numberOfUnsecuredDebts}
                onChange={handleInputs}
                onKeyDown={preventChange}
                min="1"
              />
              <label
                htmlFor="numberOfUnsecuredDebts"
                className="form-label input-label"
              >
                Number of unsecured debts
              </label>
            </div>
            <p className="text-danger">{errors.numberOfUnsecuredDebts}</p>
          </div>
          <div className="mb-3">
            <div className="form-control">
              <input
                type="number"
                name="totalEMI"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.totalEMI}
                onChange={handleInputs}
                onKeyDown={preventChange}
                min="1"
              />
              <label htmlFor="totalEMI" className="form-label input-label">
                Total EMI of unsecured debts (Rs)
              </label>
            </div>
            <p className="text-danger">{errors.totalEMI}</p>
          </div>
          <div className="mb-3">
            <div className="form-control">
              <select
                name="missedEMI"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.missedEMI}
                onChange={handleInputs}
              >
                <option disabled></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <label htmlFor="missedEMI" className="form-label input-label">
                Missed an EMI in last 3 months?
              </label>
            </div>
            <p className="text-danger">{errors.missedEMI}</p>
          </div>
          <div className="mb-3">
            <div className="form-control">
              <select
                name="harassmentType"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.harassmentType}
                onChange={handleInputs}
              >
                <option disabled></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <label
                htmlFor="harassmentType"
                className="form-label input-label"
              >
                Experiencing any harassment?
              </label>
            </div>
            <p className="text-danger">{errors.harassmentType}</p>
          </div>
          <div className="mb-3">
            <div className="form-control">
              <select
                name="legalActionType"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.legalActionType}
                onChange={handleInputs}
              >
                <option disabled></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <label
                htmlFor="legalActionType"
                className="form-label input-label"
              >
                Experiencing any legal action?
              </label>
            </div>
            <p className="text-danger">{errors.legalActionType}</p>
          </div>
        </div>
        <div className="text-center">
          <p className={`${error ? "text-danger" : "text-success"} mb-2`}>
            {message}
          </p>
          <button className="button" onClick={validate}>
            Proceed
          </button>
        </div>
      </div>

      {/*  */}
      <div className={`custom-modal-container ${modalVisible ? "active" : ""}`}>
        <div
          className="custom-modal-background"
          onClick={() => setModalVisible(false)}
        ></div>
        <div className="custom-modal-box">
          <div className="header p-2">
            <p className="fw-bold text-capitalize">
              Your unsecure debts and type of harassment
            </p>
          </div>
          <div className="body p-2">
            <p>
              Total unsecured debts:{" "}
              <span className="fw-bold">
                {" "}
                <FaRupeeSign />
                {inputs.totalUnsecuredDebts}
              </span>
            </p>
            <p>
              Number of unsecured debts:{" "}
              <span className="fw-bold">{inputs.numberOfUnsecuredDebts}</span>
            </p>
            <p>
              Total EMI:{" "}
              <span className="fw-bold">
                <FaRupeeSign />
                {inputs.totalEMI}
              </span>
            </p>
            <p>
              Have you missed EMI in last 3 months?:{" "}
              <span className="fw-bold">{inputs.missedEMI}</span>
            </p>
            <p>
              Experiencing any harassment?:{" "}
              <span className="fw-bold">{inputs.harassmentType}</span>
            </p>
            <p>
              Experiencing any legal action?:{" "}
              <span className="fw-bold">{inputs.legalActionType}</span>
            </p>
          </div>
          <div className="footer text-end p-2">
            {!error && <p className="text-success">{message}</p>}
            <div className="modal-button-flex">
              <button
                className="button text-center"
                style={{ minWidth: "80px" }}
                onClick={() => setModalVisible(false)}
              >
                Close
              </button>
              <button
                className="button text-center"
                style={{ minWidth: "80px" }}
                onClick={submit}
                id="form-1-button"
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>Confirm</>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div className={`custom-modal-container ${lessOSModal ? "active" : ""}`}>
        <div
          className="custom-modal-background"
          onClick={() => setLessOSModal(false)}
        ></div>
        <div className="custom-modal-box">
          <div className="body rounded text-center py-4 p-2">
            <p>
              Your outstanding amount is less; please reach out to our financial
              advisor. <br />
              <br />
              <a href="tel:02268762605" className="primary-text fw-bold">
                {" "}
                <FaPhoneAlt /> 02268762605
              </a>
            </p>
            <div className="modal-button-flex justify-content-center mt-4">
              <button
                className="button text-center"
                style={{ minWidth: "80px" }}
                onClick={() => setLessOSModal(false)}
              >
                Close
              </button>
              <a
                href="tel:02268762605"
                className="button text-center d-block"
                id="form-1-button"
                style={{ minWidth: "80px" }}
              >
                Call
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnrollForm1;
