import React from "react";
import advocate from "../assets/images/Advocate.jpg";

const Assistance = ({ body }) => {
  return (
    <>
      <div className="assistance">
        <div className="assistance-content">
          <h1>Hi! I'm Shweta</h1>
          <p>{body}</p>
        </div>
        <div className="advocate">
          <img src={advocate} alt="" />
        </div>
      </div>
    </>
  );
};

export default Assistance;
