import one from "../images/enroll-svg/1.png";
import two from "../images/enroll-svg/2.png";
import three from "../images/enroll-svg/3.png";
//
import debtSolutionPlan from "../images/description/debt_solution_plan.png";
import debtManagementPlan from "../images/description/debt_management_plan.png";
import harassmentSolutions from "../images/description/harassment_solutions.png";
import ourFees from "../images/description/our_fees.png";
//
import boostCreditScore from "../images/description/boost_credit_score.png";
import futureLoans from "../images/description/our_fees.png";
import sheild from "../images/description/harassment_solutions.png";
import financialSupport from "../images/description/financial_support.png";
import realTimeTracking from "../images/description/debt_management_plan.png";
//
import temp from "../images/temp.png";
import emi from "../images/golden-box/emi.svg";
import harassment from "../images/golden-box/harassment.svg";
import creditScore from "../images/golden-box/credit-score.svg";
import debtFree from "../images/golden-box/debt-free.svg";

export const enrollIntroduction = [
  {
    img: one,
    title: "Share your debts and type of harassment",
    body: "Provide us with information about the type of harassment and your current debts. This helps us understand your situation and tailor the best legal and debt solution that fits your needs.",
  },
  {
    img: two,
    title: "Outline your Income and Expenses",
    body: "Tell us about your income and monthly expenditures. This will allow us to assess your financial landscape and identify the best options for you.",
  },
  {
    img: three,
    title: "Harassment and Debt Solutions",
    body: "Based on the information you provide, we will present personalized solutions to stop harassments and debt designed to help you regain control and achieve financial stability.",
  },
];

// export const description = [
//   {
//     title: "Debt Solution Plan",
//     body: "It's looking good, We've found 4 debt and 2 harassment solutions that match your profile based on the information you have provided.",
//     img: debtSolutionPlan,
//   },
//   {
//     title: "Debt Management Plan",
//     body: "Our lawyers will negotiate with all your creditors a structured repayment plan that consolidates all unsecured debts into a single monthly payment.",
//     img: debtManagementPlan,
//   },
//   {
//     title: "Harassment Solutions",
//     body: "We'll handle all calls for you, prevent creditors from contacting your friends and family.",
//     img: harassmentSolutions,
//     subTitles: {
//       title1: "Shield from Creditor Calls",
//       text1: "We protect you from aggressive creditor calls.",
//       title2: "Full Legal Coverage",
//       text2: "Comprehensive legal protection against harassment.",
//     },
//   },
//   {
//     title: "Our One Time Fees Rs 599",
//     body: (
//       <ul className="list-unstyled">
//         <li className="label-flex">
//           {" "}
//           <span className="fw-bold">-</span> One affordable EMI for all your
//           unsecured loans
//         </li>
//         <li className="label-flex">
//           {" "}
//           <span className="fw-bold">-</span> Stop Creditor Harassment
//         </li>
//         <li className="label-flex">
//           {" "}
//           <span className="fw-bold">-</span> Boost Your Credit Score
//         </li>
//         <li className="label-flex">
//           {" "}
//           <span className="fw-bold">-</span> Become Debt Free
//         </li>
//       </ul>
//     ),
//     img: ourFees,
//   },
// ];

export const description = [
  {
    title: "Debt Solution Plan",
    body: "Our lawyers will negotiate with your creditors for one affordable EMI.",
    img: debtSolutionPlan,
  },
  {
    title: "Harassment Management Plan",
    body: "We'll handle all the calls for you, prevent creditors from contacting your friends and family, stop unannounced visits, and provide complete legal support, including responding to notices and attending hearings.",
    img: debtManagementPlan,
  },
  {
    title: "Our One Time Fees Rs 599",

    body: [
      {
        content: "One affordable EMI for all your unsecured loans",
        img: emi,
      },
      {
        content: "Relief Creditor Harassment",
        img: harassment,
      },
      {
        content: "Boost Your Credit Score",
        img: creditScore,
      },
      {
        content: "Become Debt Free",
        img: debtFree,
      },
    ],
    img: ourFees,
  },
];

export const offersData = [
  {
    title: "Boost Your Credit Score",
    body: "Enroll in our Debt Management Plan (DMP) to improve your credit score, without affecting your credit report like loan settlements do.",
    img: boostCreditScore,
  },
  {
    title: "Access to Future Loans",
    body: "Completing your DMP strengthens your credit profile, making you eligible for future loans.",
    img: futureLoans,
  },
  {
    title: "Shield from Creditor Harassment & Legal Protection",
    body: "We handle all creditor communications and legal matters, including harassment, notices, arbitration, and court representation, keeping you protected 24/7.",
    img: sheild,
  },
  {
    title: "Personalized Financial Support",
    body: "Get a dedicated relationship manager for customized budgeting and financial advice.",
    img: financialSupport,
  },
  {
    title: "Real-Time Tracking",
    body: "Track your payment progress in real time through our app and online portal.",
    img: realTimeTracking,
  },
];
